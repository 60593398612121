<template>
  <div class="NotFound">
    <LoadingPage :show="isPageLoading" />
  </div>
</template>

<script>
import LoadingPage from '@/components/LoadingPage'
import { mapGetters } from 'vuex'

export default {
  name: 'NotFound',
  computed: {
    ...mapGetters([
      'isAppInitialized',
      'isLoggedIn',
    ]),
  },
  components: { LoadingPage },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (!this.isAppInitialized) return

      clearInterval(waitForLoad)

      if (this.isLoggedIn) {
        this.$router.push({ name: 'AdminDashboard' })
        return
      }

      this.$router.push({ name: 'AdminLogin' })
    }, 50)
  },
}
</script>

<style lang="stylus" scoped>
  .NotFound
    display block
</style>
